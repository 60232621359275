<template>
    <div>
        <div class="timer text-xl-center">
            <div v-if="timer.type == 'set'">
                <div class="timer-type">
                    <a v-if="timer.paused" href="" @click.prevent="playTimer()"><i class="icon-sm fe-play-circle"></i></a>
                    <a v-if="!timer.paused" href="" @click.prevent="pauseTimer()"><i class="icon-sm fe-pause-circle"></i></a>
                    <a href="" @click.prevent="startTimer()"><i class="icon-sm fe-refresh-cw"></i></a>
                </div>
            </div>
            <div class="dropdown z-index-max">
                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="[{'cooldown': coolDown, 'disable-off': (timer.type == 'disable')}]">
                    <span v-if="timer.type == 'universal'">
                        <span class="timer-type" v-if="!coolDown">Universal</span>
                        <span class="timer-type" v-else>Cool down...</span>
                        <i class="icon-sm fe-clock"></i> <span class="timer-countdown">{{ formatGlobalTimeLeft }}</span> <i class="icon-sm fe-chevron-down"></i>
                    </span>
                    <span v-if="timer.type == 'set'">
                        <i class="icon-sm fe-clock"></i> <span class="timer-countdown">{{ formatTimeLeft }}</span> <i class="icon-sm fe-chevron-down"></i>
                    </span>
                    <span v-if="timer.type == 'disable'">
                        <span class="timer-type">Disabled</span>
                        <i class="icon-sm fe-clock"></i> <span class="timer-countdown">--:--</span> <i class="icon-sm fe-chevron-down"></i>
                    </span>
                </a>
                <div class="dropdown-menu">
                    <a class="dropdown-item d-flex align-items-center" data-toggle="modal" data-target="#set-timer"> Set Timer</a>
                    <a @click.prevent="timer.type = 'universal'; startGlobalTimer();" class="dropdown-item d-flex align-items-center"> Universal</a>
                    <a @click.prevent="timer.type = 'disable'; stopBothTimers()" class="dropdown-item d-flex align-items-center"> Disable</a>
                </div>
            </div>
        </div>

        <div class="modal fade" id="set-timer" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">

                    <div class="modal-header">
                        <div class="media flex-fill">
                            <div class="icon-shape rounded-lg bg-primary text-white mr-5">
                                <i class="fe-clock"></i>
                            </div>
                            <div class="media-body align-self-center">
                                <h5 class="modal-title">Set Timer</h5>
                                <p class="small">Sprint on your own timer</p>
                            </div>
                        </div>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form action="#">
                            <div class="form-row">
                                <div class="form-group col">
                                    <label for="invite-email" class="small d-block">Minutes</label>
                                    <select name="minutes" class="form-control" v-model="timer.minutes">
                                        <option 
                                            v-for="(i, index) in [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]" :key="'i' + index" 
                                            :value="i"
                                            >
                                            {{ i }}
                                            </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" checked="" id="checkbox-remember">
                                    <label class="custom-control-label" for="checkbox-remember" v-model="timer.remember">Remember this for next time</label>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-lg btn-block btn-primary d-flex align-items-center" data-dismiss="modal" @click="startTimer">
                            Start Sprint
                            <i class="fe-arrow-right ml-auto"></i>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>




</template>


<script>
import moment from 'moment'

export default 
{
    data() {
        return {
            timerGlobal: null,
            timeGlobalInterval: null,
            timerGlobalTimeout: null,
            coolDown: false,
            timer: {
                type: 'universal',
                minutes: 25,
                timeout: null,
                value: 0,
                time: null,
                remember: true,
                paused: false,
            },
        };
    },


    computed: {

        formatGlobalTimeLeft() {
            return this.formatTime(this.timerGlobal)
        },

        formatTimeLeft() {
            return this.formatTime(this.timer.value)
        }
    },

    mounted() 
    {
        this.startGlobalTimer();

        $('#set-timer').appendTo("body")

        // If they have selected to remember timer, set it as default
        var timerMinutes = localStorage.getItem('rememberTimer')
        if (timerMinutes != "null") {
            this.timer.minutes = timerMinutes
        }
    },

    created() {
         
    },

    watch: {
        'timer.value': function (newValue) {
            if (newValue == 0) {
                this.stopTimer()
            }
        }
    },

    methods: {

        formatTime(timeLeft) {
            let minutes = Math.floor(timeLeft / 60);
            let seconds = timeLeft % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }    

            return `${minutes}:${seconds}`;
        },

        startTimer() 
        {
            var now = moment();
            this.timer.time = now.add({minutes: this.timer.minutes})
            this.timer.type = 'set'
            this.timer.value = this.timer.time.diff(moment(), "seconds");

            // Clear global timer, as we don't want it running
            this.stopBothTimers()

            // If they selected remember timer, save to local
            if (this.timer.remember) {
                localStorage.setItem('rememberTimer', this.timer.minutes)
            }
            else {
                localStorage.removeItem('rememberTimer')
            }
            this.playTimer()
        },

        playTimer() {
            var now = moment();

            // Need to add the difference that has passed
            if (this.timer.paused) {
                var seconds = now.diff(this.timer.paused, "seconds");
                this.timer.time.add('second')
            }

            this.timer.paused = false
            this.setTimerInterval()
        },

        pauseTimer() {
            clearTimeout(this.timer.timeout);
            this.timer.paused = moment()
        },

        stopTimer() {
            clearTimeout(this.timer.timeout);
            this.timer.paused = false
        },

        startGlobalTimer() 
        {
            this.stopBothTimers()
            this.setGlobalInterval()
        },

        stopGlobalTimer() {
            clearTimeout(this.timerGlobalTimeout);
            this.timerGlobal = 0
        },
    
        stopBothTimers() {
            this.stopTimer()
            this.stopGlobalTimer()
        },

        setGlobalInterval() {
            var now = moment();
            var hour = now.hour();
            
            this.coolDown = false
            var myTime = null

            if(now.minute() < 25) {
                myTime = now.set({minute: 25, second: 0});
            }
            else if (now.minute() < 30) {
                this.coolDown = true;
                myTime = now.set({minute: 30, second: 0});
            }
            else if (now.minute() >= 30 && now.minute() < 55)
            {
                myTime = now.set({minute: 55, second: 0});
            }
            else if (now.minute() >= 55)
            {
                this.coolDown = true;
                var newHour = hour + 1;
                myTime = now.set({hour: newHour, minute: 0, second: 0});
            }
            else {
                myTime = now.set({minute: 0, second: 0});
            }
            this.timerGlobal = myTime.diff(moment(), "seconds");
            this.timerGlobalTimeout = setTimeout(this.setGlobalInterval, 1000);
        },

        setTimerInterval() {
            this.coolDown = false
            this.timer.value = this.timer.time.diff(moment(), "seconds");
            this.timer.timeout = setTimeout(this.setTimerInterval, 1000);
        }
    }
};
</script>


<style>
.cooldown {
    color: #333;
}
.dark .cooldown {
    color: #565656;
} 
.timer-type {
    display: block;
    color: #ccc;
    font-size: 12px
}
.timer-type a {
    color: #ccc;
}
a.disable-off {
    color: #ccc;
}
.timer i {
    vertical-align: -2px;
    margin-right: 4px;
}
.timer-countdown {
    margin-right: 5px;
}
.timer .dropdown-menu {
    margin-top: 5px;
    box-shadow: 0px 11px 25px rgba(0,0,0,.15);
}
</style>